
import { computed, defineComponent, onMounted } from 'vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Inquires from '@/core/services/Callable/Inquiry'
import router from '@/router/clean';
import { store } from '@/store'

export default defineComponent({
    setup() {
        
        const listing = computed(() => {
            return store.state.inquiry.inquiry_list_payload
        })

        const loading = computed(() => {
            return store.state.inquiry.inquiry_loading
        })
        
        onMounted(async() => {
            setCurrentPageBreadcrumbs("Inquires (Group Health) Listing", []);
            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            await getLists(page)
        })

        const getLists = async (value: any) => {
            const page = parseInt(value ? value : 1);
            listing.value.index = page;
            router.replace({ query: { page } })
            .catch(error => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
                console.log(store.state.inquiry.inquiry);
            await Inquires.getInquiry(listing.value)
        }

        return {
            listing,
            loading
        }
    },
})
